module.exports = doc => {
  // console.log(doc.type, doc.uid)
  switch (doc.type) {
    case "project":
      return `/project/${doc.uid}`

    default:
      return "/"
  }
}
