import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { usePrismicPreview } from "gatsby-source-prismic"
const linkResolver = require("../core/linkResolver")

const PreviewPage = () => {
  // const pathResolver = () => doc => linkResolver(doc, '');
  // const pathResolver = () => doc => linkResolver(doc)

  const { isPreview, isLoading, previewData, path } = usePrismicPreview({
    repositoryName: "annesophieauclerc",
    // pathResolver,
    linkResolver: () => linkResolver,
  })

  // This useEffect runs when values from usePrismicPreview update. When
  // preview data is available, this will save the data globally and redirect to
  // the previewed document's page.
  useEffect(() => {
    // If this is not a preview, skip.
    //   null = Not yet determined if previewing.
    //   true = Preview is available.
    //   false = Preview is not available.
    if (isPreview === false || isPreview === undefined) return
    // if (previewData === undefined && path === undefined) return
    // console.log("previewData", previewData)
    console.log("path", path)
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData
      navigate(path)
    }
    // window.__PRISMIC_PREVIEW_DATA__ = previewData
    // navigate(path)

    // console.log("previewData", previewData)

    // window.__PRISMIC_PREVIEW_DATA__ = previewData
    // console.log(previewData)

    // Navigate to the document's page.
    // navigate(path)
  }, [isPreview, isLoading, previewData, path])

  // Tell the user if this is not a preview.
  if (isPreview === false) return <div>Not a preview!</div>

  return <div>Loading preview...</div>
}

export default PreviewPage
